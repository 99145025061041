import {
  BindingSourceType,
  DisciplineDto,
  FamilySymbolSetDto,
  FamilyVersionDto,
  FullVersionParameterDto,
  MaterialDto,
  TableFamilyDto,
  ValueSetDto,
} from '@common/dto';
import { BindingsCheckParametersDrawerSaveData } from '@features/bindings-check/interfaces';
import { EBindingsCheckSourceType } from '../types/check-bindings';

export class SetParameters {
  static readonly type = '[BindingsCheck] Set parameters';

  constructor(public bdsId: string, public parameters: {}) {}
}

export class SetFamily {
  static readonly type = '[BindingsCheck] Set family';

  constructor(public family: TableFamilyDto) {}
}

export class SetVersion {
  static readonly type = '[BindingsCheck] Set version';

  constructor(public version: FamilyVersionDto) {}
}

export class SetSymbol {
  static readonly type = '[BindingsCheck] Set symbol';

  constructor(public symbol: FamilySymbolSetDto) {}
}

export class SetFamilyVersionSet {
  static readonly type = '[BindingsCheck] Set family version set';

  constructor(public set: ValueSetDto) {}
}

export class SetFunctionalTypeParameters {
  static readonly type = '[BindingsCheck] Set functional type parameters';

  constructor(public initialParameters: FullVersionParameterDto[]) {}
}

export class SetBdsIds {
  static readonly type = '[BindingsCheck] Set Bds ids';

  constructor(public ids: string[]) {}
}

export class SetDataSource {
  static readonly type = '[BindingsCheck] Set data source';
  constructor(public dataSource: EBindingsCheckSourceType) {}
}

export class SetCheckParameters {
  static readonly type = '[BindingsCheck] Set check parameters';

  constructor(public data: BindingsCheckParametersDrawerSaveData) {}
}

export class SetMaterial {
  static readonly type = '[BindingsCheck] Set material';

  constructor(public localId: string, public material: MaterialDto) {}
}

export class SetMaterialQuantity {
  static readonly type = '[BindingsCheck] Set material quantity';

  constructor(public localId: string, public quantity: number) {}
}

export class RemoveMaterial {
  static readonly type = '[BindingsCheck] Remove material';

  constructor(public localId: string) {}
}

export class AddEmptyMaterial {
  static readonly type = '[BindingsCheck] Add empty material';
}

export class SetDiscipline {
  static readonly type = '[BindingsCheck] Set discipline';

  constructor(public discipline: DisciplineDto) {}
}

export class SetSourceType {
  static readonly type = '[BindingsCheck] Set source type';

  constructor(public sourceType: BindingSourceType) {}
}

export class SetSuccessOnly {
  static readonly type = '[BindingsCheck] Set successOnly';

  constructor(public value: boolean) {}
}

export class SetIncludeNotMatchableSpecs {
  static readonly type = '[BindingsCheck] Set includeNotMatchableSpecs';

  constructor(public value: boolean) {}
}

export class Check {
  static readonly type = '[BindingsCheck] Check';

  constructor(public isNomenclature: boolean) {}
}
