import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IdNameDto, UniqueParameterCreate, UniqueParameterDto, UniqueParameterUpdate } from '@common/dto';

import { UniqueParametersFilterParams } from '@common/models/filters';
import { ApiPaths } from '@common/consts';
import { CrudService, ResultPart } from '@common/interfaces';
import { Observable } from 'rxjs';
import { UniqueParametersSpreadSheetDto } from '@common/dto/UniqueParameters';

@Injectable({ providedIn: 'root' })
export class UniqueParametersApiService implements CrudService<UniqueParameterDto> {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter: UniqueParametersFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<UniqueParametersSpreadSheetDto>>(`${ApiPaths.UniqueParameters}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param parameterId - id параметра
   */
  public getById(parameterId: number) {
    return this.http.get<UniqueParameterDto>(`${ApiPaths.UniqueParameters}/${parameterId}`);
  }

  /**
   * Добавляет новый объект
   * @param parameter - новый параметр
   */
  public create(parameter: UniqueParameterCreate) {
    return this.http.post<UniqueParameterDto>(ApiPaths.UniqueParameters, parameter);
  }

  /**
   * Обновляет объект
   * @param parameter - изменённые данные
   */
  public update(parameter: UniqueParameterUpdate) {
    return this.http.put<UniqueParameterDto>(`${ApiPaths.UniqueParameters}/${parameter.id}`, parameter);
  }

  /**
   * Удаляет объект
   * @param parameterId - id параметра
   */
  public remove(parameterId: number) {
    return this.http.delete<void>(`${ApiPaths.UniqueParameters}/${parameterId}`);
  }

  /**
   * Поиск объекта.
   * @param query - поисковая строка
   */
  public search(query: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.UniqueParameters}/smartSearch`, {
      params: {
        searchString: query,
      },
    });
  }

  /**
   * Добавляет несколько объектов.
   * @param parameters - параметры
   */
  public createBatch(parameters: UniqueParameterDto[]) {
    return this.http.post<UniqueParameterDto[]>(`${ApiPaths.UniqueParameters}/batch`, parameters);
  }

  /**
   * Возвращает уникальные параметры по id[]
   * @param ids - айди уникальных параметров
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.UniqueParameters}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public restore(id: number): Observable<UniqueParameterDto> {
    return this.http.post<UniqueParameterDto>(`${ApiPaths.UniqueParameters}/unarchive/${id}`, {});
  }
}
