import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IdNameDto, ParameterDto, ParametersSpreadSheetDto, ParameterUpdate, PatchOperation } from '@common/dto';
import { ParametersFilterParams, ParametersSmartSearchFilterParams } from '@common/models/filters';
import { ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts/api-paths';

@Injectable({ providedIn: 'root' })
export class ParametersApiService {
  constructor(private http: HttpClient) {}

  public getList(filter?: ParametersFilterParams): Observable<ResultPart<ParametersSpreadSheetDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<ParametersSpreadSheetDto>>(`${ApiPaths.Parameters}/filter`, params);
  }

  /**
   * Поиск объекта.
   */
  public smartSearch(filter: ParametersSmartSearchFilterParams): Observable<IdNameDto[]> {
    const params = filter ? filter.toQueryParams() : undefined;

    return this.http.get<IdNameDto[]>(`${ApiPaths.Parameters}/smartSearch`, { params });
  }

  public getById(entityId: number): Observable<ParameterDto> {
    return this.http.get<ParameterDto>(`${ApiPaths.Parameters}/${entityId}`);
  }

  public update(parameter: ParameterUpdate): Observable<ParameterDto> {
    return this.http.put<ParameterDto>(`${ApiPaths.Parameters}/${parameter.id}`, parameter);
  }

  public patch(entityId: number, patchData: PatchOperation[]) {
    return this.http.patch<ParameterDto>(`${ApiPaths.Parameters}/${entityId}`, patchData);
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Parameters}/${entityId}`);
  }

  public create(parameter: ParameterUpdate): Observable<ParameterDto> {
    return this.http.post<ParameterDto>(ApiPaths.Parameters, parameter);
  }

  /**
   * Возвращает параметры по id[]
   * @param ids - айди параметров
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Parameters}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public restore(id: number): Observable<ParameterDto> {
    return this.http.post<ParameterDto>(`${ApiPaths.Parameters}/unarchive/${id}`, {});
  }
}
